<template>
  <div class="n-header">
    <div class="container">
      <!-- logo -->
      <div class="n-logo">
        <!-- <img src="/imgs/logo.png" alt="" /> -->
        <a href="/">星辰物联官网 </a>
      </div>
      <!-- 导航 -->
      <div class="n-list">
        <a href="/">首页</a>
        <a href="/#/ac1">收费资讯</a>
        <a href="/#/info">关于我们</a>
        <a href="/#/xy">用户协议</a>
      </div>
      <!-- 登录注册 -->
      <div class="n-login">
        <i class="iconfont icon-denglu"></i>
        <div class="lg">
          <span class="n-login" @click="goLogin()" v-show="this.LoggedShow">
            登录
          </span>
          <i v-show="this.LoggedShow">/</i>
          <span class="n-login" @click="goRegister()" v-show="this.LoggedShow">
            注册
          </span>
        </div>

        <!-- 用户名 -->
        <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"><i
            class="iconfont icon-denglu"></i></span>

        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
      k: this.$route.query.k,
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      if (this.k) {
        window.localStorage.setItem("state", "10");
        window.localStorage.removeItem("k");
        this.$router.push("/");
        location.reload();
      } else {
        window.localStorage.setItem("state", "10");
        this.$router.push("/");
        location.reload();
      }

      // this.LoggedShow = true;

      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-header {
  width: 100%;
  position: relative;
  height: 80px;
  background: #fff;

  //   border-bottom: 1px solid #ccc;
  .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // logo
    .n-logo {

      // width: 200px;
      // height: 80px;
      a {
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    // 导航
    .n-list {
      width: 400px;
      height: 100%;
      display: flex;
      align-items: center;

      a {
        font-size: 14px;
        color: #333;
        width: 80px;
        margin: 0 15px;
        // flex: 1;
        height: 80px;
        line-height: 80px;
        text-align: center;
        border-bottom: 2px solid #fff;

        &:hover {
          border-bottom: 2px solid $colorZ;
        }
      }
    }

    // 登录注册
    .n-login {
      display: flex;
      height: 100%;
      align-items: center;

      &:hover {
        i {
          &:nth-child(1) {
            transform: rotate(365deg);
          }
        }
      }

      i {
        font-size: 21px;
        margin-right: 10px;
        transition: all 0.2s;
      }

      .lg {
        // width: 200px;
        display: flex;
        align-items: center;
        height: 100%;

        i {
          margin: 0 5px;
        }
      }

      span {
        cursor: pointer;
        font-size: 14px;

        &:hover {
          color: $colorZ;
        }
      }
    }
  }
}</style>